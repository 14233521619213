<template>
    <transition name="fade">
        <div
            class="toast-container"
            :class="'container-' + type"
            v-if="visible"
        >
            <div class="toast" :class="type">
                <div class="content">
                    <i class="iconfont" :class="'icon-' + type"></i>
                    <span>{{ message }}</span>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: "Toast",
    data() {
        return {
            message: "",
            visible: false,
            type: "error", //四种类型：info, success, warning, error
        };
    }
};
</script>
<style lang="scss" scoped>
.toast-container {
    position: fixed;
    z-index: 999;
    top: 8%;
    right: 20px;
    padding: 5px 20px;
    color: #000;
    background-color: #424242;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
}
.toast {
    span {
        margin-left: 10px;
    }
}
.container {
    padding: 8px 48px 8px 38px;
}
.container-info {
    border: 1px solid #d5e8fc;
    background-color: #eaf4fe;
}
.container-success {
    border: 1px solid #d1f2e1;
    background-color: #e8f9f0;
}
.container-warning {
    border: 1px solid #ffebcc;
    background-color: #fff5e6;
}
.container-error {
    border: 1px solid #fbd9d0;
    background-color: #fdece8;
}
.icon-success {
    color: #19be6b;
}

.icon-error {
    color: #ed3f14;
}

.icon-info {
    color: #2d8cf0;
}

.icon-warning {
    color: #f90;
}
</style>