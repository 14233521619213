<template>
    <div id="app">
        <!-- <ScaleBox :width="3096" :height="1118" bgc="transparent" :delay="100">
            <router-view />
        </ScaleBox> -->

        <ScaleBox :width="2300" :height="1150" bgc="transparent" :delay="100">
            <router-view />
        </ScaleBox>

        <!-- 背景粒子动画 -->
        <template v-if="logoSate">
            <div v-for="(item, index) in 50" :key="index" class="circle-container">
                <div class="circle"></div>
            </div>
        </template>
    </div>
</template>
  <script>
import ScaleBox from "vue2-scale-box";
export default {
    components: {
        ScaleBox
    },
    data() {
        return {
           logoSate: true
        }
    },
    created() {
        if(location.hash == '#/login') {
            this.logoSate = false;
        } else {
            this.logoSate = true;
        }
    }
};
</script>
<style lang='scss'>
@import '@/assets/iconfont/iconfont.css';
@import '@/assets/css/comm.scss';
@import '@/assets/css/lizi.scss';
#app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #051623;
    position: relative;
}
</style>