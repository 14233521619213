export default [
    {
      path: '/',
      redirect: to => {
            let logoSate = window.sessionStorage.getItem('logoSate');
            if (logoSate == null) {
                return '/login'
            } else {
                return '/index'
            }
        }
    }, {
        name: 'login',
        path: '/login',
        component: 'home/logo',
        meta: { menuCode: '', title: '登录' }
    }, {
      name: 'index',
      path: '/index',
      component: 'home/index',
      meta: { menuCode: '', title: '' }
    }, {
        name: 'province',
        path: '/province',
        component: 'home/province',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'cpv',
        path: '/cpv',
        component: 'type/cpv',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'piles',
        path: '/piles',
        component: 'type/piles',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'city',
        path: '/city',
        component: 'home/city',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'type',
        path: '/type',
        component: 'type/index',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'wind',
        path: '/wind',
        component: 'type/wind',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'stored',
        path: '/stored',
        component: 'type/stored',
        meta: { menuCode: '', title: '' }
    }, {
        name: 'welink',
        path: '/welink',
        component: 'type/welink',
        meta: { menuCode: '', title: '' }
    }

  ]