import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnimateNumber from 'vue-animate-number'

import dataV from '@jiaminghi/data-view'
// Toast
import Toast from '@/components/toast/index';
// 引入 echarts
import * as echarts from 'echarts'
import 'echarts-gl'
import "echarts-liquidfill";
// 使用normalize.css来消除浏览器之间的基础样式差异
import "normalize.css";
// 动画库
import 'animate.css';
Vue.use(dataV)
Vue.use(Toast);
Vue.use(VueAnimateNumber)
// 挂载到vue实例中
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
