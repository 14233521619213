import Vue from 'vue'
import Router from 'vue-router'

import routes from './router.config'

Vue.use(Router)

const createRoute = routes => {
    return routes.reduce((processedRoutes, currentRoute) => {
        processedRoutes.push(processRouteObj(currentRoute))
        return processedRoutes
    }, [])
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};



const processRouteObj = ({ menuCode, breadcrumb, children, component, ...args }) => {
    return Object.assign(
        {
            meta: { menuCode },
            props: {
                breadcrumbObj: {
                    content: breadcrumb,
                    menuCode: menuCode
                }
            },
            component: () => import(/* webpackInclude: /\.(js|vue)$/ */ `@/pages/${component}`),
            children: children ? createRoute(children) : []
        },
        args
    )
}


const router = new Router({
//   mode: 'history',
  base: process.env.BASE_URL,
  routes: createRoute(routes)
})

router.beforeEach(async (to, form, next) => {
    // 修改跳转页面title
    document.title =  to.meta.title || '博邦可视化';
    let logoSate = sessionStorage.getItem('logoSate') || true;
    if (to.path === '/login') {
        if (!logoSate) {
            return next('/index');
        }
        return next();
    }
    if (!logoSate) return next('/login'); // 没有token 强制跳转
    const tokenStartTime = window.sessionStorage.getItem('tokenStartTime');
    const timeOver = 6 * 24 * 3600 * 1000;
    let date = new Date().getTime();
    console.log(date, '当前时间', tokenStartTime, timeOver)
    console.log(date - tokenStartTime)
    if (date - tokenStartTime > timeOver) {
        sessionStorage.setItem('logoSate', null);
        logoSate = null;
    }
    if (!logoSate) {
        if (to.path == '/login') return next();
        // this.$toast('error', '登录状态过期，请重新登录!');
        return next('/login');
    } else if (to.path == '/login') {
        return next('');
    }
    next();
})

export default router
